import React from 'react';
// import data
import { features } from '../data';
// import { HiArrowRight } from 'react-icons/hi';

const Feature4 = () => {
  // destructure features
  const { feature4 } = features;
  // destructure feature4
  const { pretitle, title, subtitle, image } = feature4;
  return (
    <section className='section'>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row lg:items-center lg:gap-x-[30px]'>
          {/* image */}
          <div
            className='flex-1 order-2 lg:order-1'
            data-aos='fade-right'
            data-aos-offset='300'
          >
            <img src={image} alt='' />
          </div>
          {/* text */}
          <div
            className='flex-1 order-1 lg:order-2'
            data-aos='fade-left'
            data-aos-offset='400'
          >
            <div className='pretitle'>{pretitle}</div>
            <h2 className='heading'>{title}</h2>
            <p className='lead'>{subtitle}</p>
            {/* <button className='btn-link flex items-center gap-x-3 hover:gap-x-5 transition-all'>
              {btnLink} <HiArrowRight />
            </button> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature4;
